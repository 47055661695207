<template>
  <div class="public">
    <div class="public__header">
      <div class="content content_narrow">
        <GoGptLogo class="public__logo" />
        <NavButton v-if="sidebarHasContent"
                   class="public__sidebar-button"
                   icon="aside-right"
                   @click="sidebarShown = !sidebarShown"/>
        <router-link v-if="$route.meta.loginButton !== false" :to="{name: 'auth', query: {url: $route.fullPath}}" custom v-slot="{ navigate }">
          <NavButton @click="navigate" icon="login" label="Войти" />
        </router-link>
        <router-link v-else :to="{name: 'chat'}" custom v-slot="{ navigate }">
          <NavButton @click="navigate" icon="chat" label="Новый диалог" />
        </router-link>
      </div>
    </div>
    <div class="public__wrapper" :class="{'public__wrapper_sidebar': sidebarHasContent}">
      <slot class="public__content"></slot>
      <aside class="public__sidebar" :class="{'public__sidebar_shown': sidebarHasContent && sidebarShown}">
        <Button icon="cancel"
                severity=""
                class="public__sidebar-close"
                @click="sidebarShown = false"/>
        <SidebarPortal @changed="sidebarHasContent = $event.hasContent"/>
      </aside>
    </div>
  </div>
</template>
<script setup>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import NavButton from '@/components/NavButton.vue';
import GoGptLogo from '@/components/GoGptLogo.vue';
import SidebarPortal from '@/components/SidebarPortal.vue';
import Button from '@/components/Button.vue';
import { useWindowResize } from '@/composables/event-listener';

const router = useRouter();
const sidebarHasContent = ref(false);
const sidebarShown = ref(false);

const hideSidebar = () => {
  sidebarShown.value = false;
};

router.afterEach(() => {
  hideSidebar();
});

const onWindowResize = () => {
  hideSidebar();
};

useWindowResize(onWindowResize);
</script>

<style lang="scss">
.public {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  &__logo{
    margin-left: .7em;
    margin-right: auto;
    padding: .5em 0;
  }

  &__header {
    background: #323235;
    color: #fff;
    padding: .5em;
    box-shadow: 0 0 10px rgba(0, 0, 0, .5);
    z-index: 1;

    .content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0 auto;
    }

    .nav-button {
      padding: .7em;
    }
  }

  &__wrapper {
    height: 0;
    flex-grow: 1;
  }
  &__content {
    flex-grow: 1;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
  }
  &__sidebar{
    background: #fafafa;
    border-left: solid #dadada 1px;
    box-sizing: border-box;
    display: none;
  }
  &__sidebar_shown {
    display: block;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    max-width: 480px;
    box-shadow: -3px 0 5px rgba(0,0,0,.15);
    z-index: 1;
    height: 100%;
  }
  &__sidebar-close{
    position: absolute;
    padding: .5em;
    right: .5em;
    top: .5em;
  }
}
@media (min-width: 1400px) {
  .public__sidebar-button{
    display: none;
  }
  .public__wrapper_sidebar {
    display: flex;
    &:before{
      content: '';
      flex-grow: 1;
      width: 0;
    }

    .public__content{
      max-width: calc(800px + 2em);
    }
    .public__sidebar{
      display: block;
      flex-grow: 1;
      width: 0;
    }
  }
}
</style>

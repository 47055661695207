<template>
  <div class="prompt">
    <slot name="buttons"></slot>
    <textarea v-if="!promptDisabled"
              ref="textareaElement"
              v-autoheight
              class="prompt__input"
              type="text"
              :placeholder="placeholder"
              :readonly="audioRecording || audioTranscribe"
              v-model="prompt"
              @keydown.enter="onKeyboardEnter" />
    <span v-else class="prompt__input prompt__input_disabled">{{ promptDisabledText }}</span>
    <PromptButton
      class="prompt__rec-button"
      :class="{'prompt__rec-button_active': audioRecording || audioTranscribe}"
      v-if="!prompt.trim()"
      icon="mic"
      :busy="audioTranscribe"
      :active="audioRecording || audioTranscribe"
      :disabled="audioTranscribe"
      @click="startAudioRec"
      />
    <PromptButton
      v-else
      :busy="busy"
      icon="send"
      :active="canSend || busy"
      :disabled="!canSend && !busy"
      @click="send" />
    <teleport to="body" v-if="audioRecording">
      <div class="mic-overlay">
        <div class="mic-overlay__icon"><MicIcon /></div>
        <div class="mt-md">Нажмите, чтобы остановить запись</div>
      </div>
    </teleport>
  </div>
</template>

<script setup>
import {
  defineProps, defineEmits, ref, watch, computed, shallowRef,
} from 'vue';
import useAppStore from '@/store/app';
import vAutoheight from '@/directives/autoheight';
import PromptButton from '@/components/PromptButton.vue';
import { useEventListener } from '@/composables/event-listener';
import api from '@/utils/api';
import MicIcon from '@/components/icons/MicIcon.vue';

const props = defineProps({
  prompt: { type: String, default: '' },
  placeholder: String,
  promptDisabled: Boolean,
  promptDisabledText: String,
  busy: Boolean,
  canSend: { type: Boolean, default: null },
});

const emit = defineEmits([
  'update:prompt',
  'input',
  'send',
]);

const appStore = useAppStore();

const textareaElement = ref(null);
const prompt = ref('');
const canSend = computed(() => (props.canSend === null ? !!prompt.value?.trim() : props.canSend));

watch(() => props.prompt, (value) => {
  prompt.value = value;
}, { immediate: true });

watch(prompt, (value) => {
  emit('update:prompt', value);
});

const focus = () => textareaElement.value?.focus();

const send = () => {
  if (!canSend.value || props.busy) {
    return;
  }
  emit('send', prompt.value);
  focus();
};

const onKeyboardEnter = (e) => {
  if (e.shiftKey || appStore.isMobile) {
    return;
  }
  e.preventDefault();
  send();
};

const audioRecording = ref(false);
const audioTranscribe = ref(false);
let mediaRecorder = null;
const startAudioRec = async () => {
  const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
  audioRecording.value = true;
  // if (!audioRecording.value) {
  //   return;
  // }
  if (mediaRecorder) {
    mediaRecorder.ondataavailable = null;
    mediaRecorder.onstop = null;
    mediaRecorder.stop();
  }
  mediaRecorder = new MediaRecorder(stream);

  const chunks = [];
  mediaRecorder.ondataavailable = (e) => {
    chunks.push(e.data);
  };

  mediaRecorder.onstop = async () => {
    audioTranscribe.value = true;
    const formData = new FormData();
    formData.append('file', new Blob(chunks));
    try {
      prompt.value = await api.post(
        'audio/transcribe',
        formData,
        { headers: { 'Content-Type': 'multipart/form-data' } },
      );
    } finally {
      audioTranscribe.value = false;
    }
  };

  mediaRecorder.start();
};

const stopAudioRec = () => {
  audioRecording.value = false;
  mediaRecorder?.stop();
};

// useEventListener(window, 'mouseup', stopAudioRec, true);
useEventListener(window, 'click', stopAudioRec, true);

defineExpose({
  focus,
});
</script>

<style lang="scss">
.mic-overlay{
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: grid;
  align-content: center;
  justify-items: center;
  color: #c33219;
  background: rgba(255,255,255,.75);
  z-index: 1;
  cursor: pointer;
  &__icon{
    svg{
      font-size: 100px;
      animation: pulse 1.5s linear infinite
    }
  }
}
@keyframes pulse {
  0%{
    transform: scale(1) ;
  }
  50%{
    transform: scale(1.1);
  }
  100%{
    transform: scale(1);
  }
}
.prompt{
  display: flex;
  align-items: end;
  padding: 1em;
  box-sizing: border-box;
  border: solid #dadada 1px;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, .1);
  gap: .5em;

  &__input {
    border: none;
    outline: none;
    width: 1px;
    flex-grow: 1;
    line-height: 1.2;
    padding: 0;
    resize: none;
    box-sizing: content-box;
    height: 1.2em;
    max-height: 14.4em;
    background: inherit;
    color: inherit;

    &_disabled {
      color: #747474;
    }
  }

  &__rec-button_active, &__rec-button_active:hover, &__rec-button_active:disabled {
    color: #f62601;
    text-shadow: 0 0 0 rgba(0, 0, 0, .5);
  }
}
</style>

<template>
  <div class="message" :class="[`message_${message.role}`, {'message_ignore': !inContext || message.ignore}]">
    <Avatar class="message__avatar" :theme="message.role">
      <Loader v-if="!message.provider" />
      <Icon v-else :icon="message.role === 'assistant' ? message.provider : 'user'" />
    </Avatar>
    <Balloon v-if="removeHandler" severity="error">
      <div class="flex gap-sm">
        <div class="link link_full-gray flex gap-sm ai-center" @click="removeHandler?.call()">
          <ConfirmIcon />
          <span>Удалить</span>
        </div>
        <div class="link link_full-gray flex gap-sm ai-center" @click="cancelRemove()">
          <CancelIcon />
          <span>Отменить ({{removeHandler.secondsLeft}} сек.)</span>
        </div>
      </div>
    </Balloon>
    <Balloon v-else-if="typing" severity="success">
      <Typing />
    </Balloon>
    <Balloon
      v-else class="message__balloon"
      :severity="message.role === 'assistant' ? 'success' : 'info'">

      <div class="mb-md" v-if="videos.length">
        <div class="message__attachments message__gallery message__gallery_list message__gallery_video">
          <a :href="i.content.url" target="_blank" class="message__attachment" v-for="i in videos" :key="i.id">
            <figure>
              <img :src="`${i.content.preview}`" alt="">
              <figcaption>{{ i.content.title }}</figcaption>
            </figure>
          </a>
        </div>
      </div>
      <div class="mb-md" v-if="images.length">
        <div class="message__attachments message__gallery">
          <div class="message__attachment" v-for="i in images" :key="i.id">
            <img :src="`${appHost}${i.content.url}`" alt="" @click="imageFullcreenUrl = $event.target.src">
          </div>
        </div>
      </div>
      <div class="mb-md" v-if="files.length">
        <div class="message__attachments message__files">
          <template v-for="l in files" :key="l.id">
            <a v-if="l.type==='webpage'" class="message__attachment link" target="_blank" :href="l.content.url">
              <GlobeIcon />
              <span>{{ l.content.url }}</span>
            </a>
            <a v-else class="message__attachment">
              <ClipIcon />
              <span>{{ l.content.url.split('/').pop() }}</span>
            </a>
          </template>
        </div>
      </div>

      <div class="message__content markdown-content" v-html="md(message?.message || '')"></div>
      <div class="mt-sm" v-if="message.role === 'assistant'">
        <Button class="message__voice-button"
                v-if="showMenu || message.voiceUrl"
                icon="voice"
                :label="voiceStatus === 'generate' ? 'Загрузка...' : (voiceStatus === 'playing' ? 'Остановить' : 'Озвучить')"
                :is-busy="voiceStatus === 'generate'"
                @click="voiceMessage"/>
        <audio ref="voiceEl" :src="`${appHost}${message.voiceUrl}`" @play="voiceStatus='playing'" @pause="voiceStatus='stopped'"></audio>
      </div>
      <div class="message__info" v-if="message?.role === 'assistant'">
        <div>
          <Icon :icon="message.provider" /> <span>{{message.model}}</span>
        </div>
        <div v-if="message.useUnits">
          <ArrowDownCircleIcon />
          {{message.useUnits}}
        </div>
      </div>
      <ChatMessageMenu
        v-if="showMenu"
        class="message__menu" :message="message"
        @remove="removeHandler = $event"
        @generate="$emit('generate', $event)"
        @edit="$emit('edit', $event)"/>
      <teleport to="body">
        <div class="image-image-fullscreen" v-if="imageFullcreenUrl" @click="imageFullcreenUrl = null">
          <img :src="imageFullcreenUrl" alt=""/>
        </div>
      </teleport>
    </Balloon>
  </div>
</template>

<script setup>
import {
  defineProps, defineEmits, ref, computed, nextTick,
} from 'vue';
import md from '@/utils/markdown';
import useChatStore from '@/store/chat';
import Typing from '@/components/Typing.vue';
import ChatMessageMenu from '@/components/ChatMessageMenu.vue';
import CancelIcon from '@/components/icons/CancelIcon.vue';
import ConfirmIcon from '@/components/icons/ConfirmIcon.vue';
import ArrowDownCircleIcon from '@/components/icons/ArrowDownCircleIcon.vue';
import Avatar from '@/components/Avatar.vue';
import Balloon from '@/components/Balloon.vue';
import GlobeIcon from '@/components/icons/GlobeIcon.vue';
import ClipIcon from '@/components/icons/ClipIcon.vue';
import Icon from '@/components/Icon.vue';
import Loader from '@/components/Loader.vue';
import Button from '@/components/Button.vue';

// eslint-disable-next-line vue/no-setup-props-destructure
const props = defineProps({
  message: Object,
  typing: Boolean,
  inContext: { type: Boolean, default: true },
  showMenu: { type: Boolean, default: true },
});
const appHost = process.env.VUE_APP_HOST;
const chatStore = useChatStore();
const images = computed(() => props.message?.attachments.filter((a) => a.type === 'image'));
const videos = computed(() => props.message?.attachments.filter((a) => a.type === 'video_text'));
const files = computed(() => props.message?.attachments.filter((a) => !/image|video_text/.test(a.type)));
const imageFullcreenUrl = ref(null);
defineEmits(['generate', 'edit']);

const removeHandler = ref(null);
const cancelRemove = () => {
  removeHandler.value?.cancel();
  removeHandler.value = null;
};

const voiceStatus = ref('stopped');
const voiceEl = ref(null);
const voiceMessage = async () => {
  try {
    if (!props.message.voiceUrl) {
      voiceStatus.value = 'generate';
      await chatStore.voiceMessage(props.message.id);
    }
    await nextTick();
    const el = voiceEl.value;
    if (el.paused) {
      el.play();
    } else {
      el.pause();
    }
  } catch {
    voiceStatus.value = 'stopped';
  }
};
</script>

<style lang="scss">
.message{
  display: flex;
  align-items: end;

  & &__balloon{
    padding-right: 2.4em;
  }

  &__attachments{
    display: inline-flex;
    flex-wrap: wrap;
    gap: .5em;
    background: rgba(255, 255, 255, .2);
    border: dotted 1px rgba(255, 255, 255, .8);
    padding: .5em;
    border-radius: 3px;
    max-width: 100%;
    box-sizing: border-box;
  }
  &__attachment{
    max-width: 100%;
    overflow: hidden;
  }
  &__gallery{
    justify-content: center;
  }
  &__gallery &__attachment{
    background: #fff;
    border-radius: 3px;
    padding: .25em;
    text-decoration: none;
    color: inherit;
    img {
      width: 150px;
      height: 100px;
      object-fit: contain;
      cursor: pointer;
    }
    figure{
      display: flex;
      align-items: center;
      margin: 0;
      flex-direction: column;
    }
    figcaption{
      display: block;
      line-height: 1.5em;
      max-height: 4.5em;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: .5em;
      font-size: .75em;
      text-align: center;
    }
  }
  &__gallery_list{
    flex-direction: column;
  }
  &__gallery_video img{
    background: #000;
    border-radius: 3px;
  }
  &__files &__attachment{
    display: flex;
    align-items: center;
    gap: .5em;
    padding: .25em;
    border-radius: 3px;
    background: #fff;
    svg{
      flex-shrink: 0;
    }
    span{
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  &__content {
    p {
      margin: 1em 0 0;
    }
    pre{
      margin: 1em 0 0;
    }
    code {
      box-sizing: border-box;
      width: 100%;
      border-radius: 5px;
    }
    & > :first-child{
      margin-top: 0;
    }
  }
  &__info{
    display: flex;
    justify-content: end;
    font-size: .7em;
    margin-top: .8em;
    margin-bottom: -1em;
    gap: 1em;
    > div{
      display: flex;
      gap: .5em;
      align-items: center;
    }
  }

  &__menu {
    position: absolute;
    right: .2em;
    top: .2em;
  }

  &__voice-button{
    padding: 0.25em;
    color: #222;
    background-color: rgba(255, 255, 255, 0.2);
    border: solid 1px rgba(255, 255, 255, 0.3);
    &:hover{
      background-color: rgba(255,255,255,.3);
    }
  }
}

.message_ignore{
  .message__avatar,
  .message__balloon{
    filter: grayscale(.9);
    transform: translateZ(0); // костыль для Safari, с ним он использует GPU, иначе filter тормозит
  }
  .message__avatar{
    opacity: .6;
  }
}

@media all and (min-width: 480px) {
  .message{
    &__gallery &__attachment{
      figure{
        flex-direction: row;
      }
      figcaption{
        text-align: left;
      }
    }
  }
}
</style>

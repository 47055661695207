<template>
  <Modal ref="modalElement" class="modal-share-link" @show="refreshUrl()">
    <template #header>Поделиться ответом</template>
    <div class="form form_st_rows">
      <div class="form__field form__row">
        <div class="form-input flex">
          <span class="fill">{{ url }}&nbsp;</span>
          <Loader class="shadow-text" v-if="!url" />
          <CopyIcon class="link link_gray" v-else-if="clipboard.supports"
                       @click="clipboard.writeText(url)" />
        </div>
        <Notice class="form-hint mt-sm" severity="info">
          Ссылка является партнёрской
        </Notice>
      </div>
      <div class="form__field form__row">
        <div class="iflex ai-center gap-sm">
          <Toggle v-model:checked="showContext" />
          <span>Показывать историю</span>
        </div>
      </div>
      <div class="form__buttons form__row">
        <Button type="button" icon="cancel" severity="" @click="$refs.modalElement.hide()">
          Закрыть
        </Button>
      </div>
    </div>
  </Modal>
</template>

<script setup>
import {
  ref, defineExpose, watch,
} from 'vue';
import api from '@/utils/api';
import Modal from '@/components/Modal.vue';
import clipboard from '@/utils/clipboard';
import Button from '@/components/Button.vue';
import CopyIcon from '@/components/icons/CopyIcon.vue';
import Loader from '@/components/Loader.vue';
import Toggle from '@/components/Toggle.vue';
import Notice from '@/components/Notice.vue';

const message = ref(null);
const url = ref(null);
const showContext = ref(false);

const modalElement = ref(null);

const refreshUrl = async () => {
  if (!message.value) {
    return;
  }
  url.value = null;
  url.value = await api.post('chat/message/share', { id: message.value.id, showContext: showContext.value });
};

watch(showContext, () => refreshUrl(), { immediate: true });

defineExpose({
  show: (m) => {
    url.value = null;
    showContext.value = false;
    message.value = m;
    modalElement.value?.show();
  },
});
</script>

<style lang="scss">
.modal-share-link .modal__window{
  width: 100%;
  max-width: 500px;
}
</style>

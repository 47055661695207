import { createApp } from 'vue';
import { createPinia } from 'pinia';
import VueNumerals from 'vue-numerals';
import GoogleSignInPlugin from 'vue3-google-signin';
import moment from 'moment';
import 'moment/locale/ru';
import '@/assets/styles.scss';
import useAuthStore from '@/store/auth';
import useChatStore from '@/store/chat';
import useImagingStore from '@/store/imaging';
import App from '@/App.vue';
import router from '@/router/index';
import { createSidebarPortal } from '@/utils/sidebar-portal';
import './registerServiceWorker';

const app = createApp(App);
app.use(createPinia());
app.use(VueNumerals, { locale: 'ru' });
moment.locale('ru');
app.use(GoogleSignInPlugin, {
  clientId: '996782677411-025d8r4nq375ludcm8ss1vp9brk49loe.apps.googleusercontent.com',
});

(async () => {
  await useAuthStore().refreshToken();
  await useChatStore().loadModels();
  await useImagingStore().loadPresets();
  app.use(router);
  app.use(createSidebarPortal());
  app.mount('#app');
})();

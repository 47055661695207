<template>
<div class="imaging-inpaint">
  <ImageMaskEditor
    ref="maskEditor"
    class="imaging-inpaint__editor"
    :src="`${appHost}${image.imageUrl}`"/>
  <Prompt
    class="imaging-inpaint__prompt"
    :can-send="true"
    placeholder="Описание"
    @send="send" />
</div>
</template>

<script setup>
import { defineProps, defineEmits, ref } from 'vue';
import ImageMaskEditor from '@/components/ImageMaskEditor.vue';
import Prompt from '@/components/Prompt.vue';

defineProps({
  image: Object,
});

const emit = defineEmits(['send']);

const appHost = process.env.VUE_APP_HOST;
const maskEditor = ref(null);

const send = async (prompt) => {
  const mask = await maskEditor.value?.getMaskImage();
  emit('send', { prompt, mask });
};

</script>

<style lang="scss">
.imaging-inpaint{
  display: flex;
  flex-direction: column;
  background: #282828;
  gap: 1em;
  &__editor{
    flex-grow: 1;
  }
  &__prompt{
    width: 100%;
    max-width: 800px;
    align-self: center;
    border: none;
    background: #444;
    color: #fff;
  }
}
</style>

<template>
  <ScrollPanel ref="messagesElement" class="chat-messages">
    <div class="chat-messages__inner">
      <div class="chat-messages__messages" v-if="messages.length">
        <ChatMessage v-for="m in messages" :key="m.id"
                     :message="m"
                     :in-context="m.id >= firstContextId"
                     :show-menu="!hideMenu"
                     @generate="$emit('generate', $event)"
                     @edit="$emit('edit', $event)"/>
        <ChatMessage :typing="true"
                     :message="{role: 'assistant'}"
                     v-show="assistantTyping"/>
      </div>
      <div class="chat-messages__empty" v-else>
        <slot name="empty"><div class="text-center shadow-text">{{ emptyText }}</div></slot>
      </div>
    </div>
  </ScrollPanel>
</template>

<script setup>
import {
  defineProps, defineEmits, watch, ref,
} from 'vue';
import ScrollPanel from '@/components/ScrollPanel.vue';
import ChatMessage from '@/components/ChatMessage.vue';

const props = defineProps({
  messages: Array,
  emptyText: { type: String, default: 'нет сообщений' },
  assistantTyping: Boolean,
  contextLimit: { type: Number, default: -1 },
  hideMenu: Boolean,
});

defineEmits(['generate', 'edit']);

const firstContextId = ref(0);
const updateFirstContextId = () => {
  const msgList = props.messages;
  let id = 0;
  for (let i = msgList.length - 1, l = props.contextLimit; i >= 0 && l; i -= 1) {
    if (!msgList[i].ignore) {
      if (id !== 0 || msgList[i].role !== 'user') {
        l -= 1;
      }
      id = msgList[i].id;
    }
  }
  firstContextId.value = id;
};

const messagesElement = ref(null);
const scrollToBottom = () => {
  messagesElement.value?.scrollToBottom();
};

watch(() => props.messages, () => {
  updateFirstContextId();
}, { deep: true });
watch(() => props.contextLimit, () => updateFirstContextId());

updateFirstContextId();

defineExpose({
  scrollToBottom,
});
</script>

<style lang="scss">
.chat-messages{
  line-height: 1.2;
  &__messages {
    display: flex;
    flex-direction: column;
    gap: 1.5em;
  }

  .message{
    width: 100%;
  }
}
</style>
